<template>
  <div class="darfts-list">
    <v-card class="letter-card">
      <v-row>
        <v-col>
          <h4>بایگانی</h4>
        </v-col>
        <v-col class="pe-2">
          <div class="custom-input">
            <v-text-field
              class="float-end"
              outlined
              dense
              style="width: 330px"
              v-model="search"
              placeholder="شماره نامه را جستجو کنید..."
            >
              <template #prepend-inner>
                <v-icon> $Magnify </v-icon>
              </template>
              <template v-if="search.length" #append>
                <v-icon @click="search = ''"> $Close </v-icon>
              </template>
            </v-text-field>
          </div>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <div class="custom-vselect pt-1 d-flex flex-row">
            <span class="ps-3 pt-3">نوع نامه</span>
            <v-select
              outlined
              dense
              :items="typeOptions"
              v-model="letterType"
              @change="currentPage = 1"
            ></v-select>
          </div>
        </v-col>
      </v-row>
      <v-card class="text-center pa-10 cards" v-if="!isLoaded">
        <div
          class="d-flex flex-column justify-content-center align-items-center"
        >
          <circle-4></circle-4>
          <h6 class="mt-6">در حال بارگذاری ...</h6>
        </div>
      </v-card>
      <div v-else>
        <v-card class="mt-4 ps-0 pe-0" v-if="search && !filteredLetters.length">
          <v-row>
            <v-col
              class="text-center text--grey text--darken-1"
              style="font-size: 15px"
            >
              <div class="border-box">
                <v-icon style="margin-left: 88px">$NoResult</v-icon>
                <p style="margin-top: 88px" class="mb-0">
                  نتیجه‌ای برای "{{ search }}" یافت نشد.
                </p>
              </div>
            </v-col>
          </v-row>
        </v-card>
        <v-card
          class="mt-4 ps-0 pe-0"
          v-if="!search && !filteredLetters.length"
        >
          <v-row>
            <v-col
              class="text-center text--grey text--darken-1"
              style="font-size: 16px"
            >
              <div class="border-box">
                <v-icon style="margin-left: 88px">$NoLetter</v-icon>
                <p style="margin-top: 88px">نامه‌ای برای نمایش وجود ندارد.</p>
              </div>
            </v-col>
          </v-row>
        </v-card>
        <div v-if="filteredLetters.length" class="custom-table mt-8">
          <b-table
            responsive
            show-empty
            stacked="sm"
            :current-page="currentPage"
            :per-page="perPage"
            :items="filteredLetters"
            :fields="fields"
            empty-filtered-text="جستجو نتیجه‌ای در بر نداشت"
            empty-text="نامه‌ای برای نمایش وجود ندارد"
            striped
            thead-class="headClass"
            tbody-class="bodyClass"
            :busy="isBusy"
          >
            <template v-slot:cell(index)="data">
              <div
                style="text-align: center; vertical-align: middle"
                class="pt-lg-3 pt-0"
              >
                {{ data.index + 1 + perPage * (currentPage - 1) }}
              </div>
            </template>
            <template v-slot:head()="data">
              <div style="text-align: center; vertical-align: middle">
                {{ data.label }}
              </div>
            </template>
            <template v-slot:cell()="data">
              <div
                style="text-align: center; vertical-align: middle"
                class="pt-lg-3 pt-0"
              >
                {{ data.value }}
              </div>
            </template>
            <template v-slot:cell(type)="data">
              <div
                style="text-align: center; vertical-align: middle"
                class="pt-lg-3 pt-0 d-flex flex-row justify-content-center"
              >
                <div
                  class="ps-6 pe-6 ms-1 me-1"
                  :class="
                    data.value[0] == 'ورودی'
                      ? 'purple-badge'
                      : data.value[0] == 'سازمانی'
                      ? 'blue-badge'
                      : 'orange-badge'
                  "
                  v-for="(item, index) in data.value"
                  :key="index"
                >
                  <span>{{ item }}</span>
                </div>
              </div>
            </template>

            <template v-slot:cell(operation)="data">
              <div style="text-align: center; vertical-align: middle">
                <v-btn
                  @click="removeArchive(data.item.id)"
                  class="pa-3"
                  :class="
                    data.item.type[0] == 'ورودی'
                      ? 'purple-mainBtn'
                      : data.item.type[0] == 'خروجی'
                      ? 'green-mainBtn'
                      : 'blue-mainBtn'
                  "
                  >ارسال به صندوق {{ data.item.type[0] }}</v-btn
                >
              </div>
            </template>
            <div slot="table-busy" class="text-center text-danger my-2">
              <v-progress-circular indeterminate color="#00acc1">
              </v-progress-circular>
            </div>
          </b-table>
          <v-row v-if="filteredLetters.length" class="mt-8">
            <v-col class="d-flex flex-row">
              <span class="pt-2">تعداد موارد نمایش در هر صفحه</span>
              <div class="blue-number-box ms-3 me-3">
                <v-select
                  dense
                  :menu-props="{ top: true, offsetY: true }"
                  outlined
                  v-model="perPage"
                  :items="perPageOptions"
                  @change="currentPage = 1"
                ></v-select>
              </div>
            </v-col>
            <v-col>
              <div
                class="d-flex flex-row justify-content-end blue-custom-pagination"
              >
                <v-btn @click="goToFirst()" class="navigation-btn"
                  ><v-icon>$ArrowDoubleRight</v-icon></v-btn
                >
                <v-pagination
                  v-model="currentPage"
                  :length="Math.ceil(filteredLetters.length / perPage)"
                  :total-visible="5"
                  prev-icon="$ArrowLeft"
                  next-icon="$ArrowRight"
                ></v-pagination>
                <v-btn @click="goToLast()" class="navigation-btn"
                  ><v-icon>$ArrowDoubleLeft</v-icon></v-btn
                >
              </div>
            </v-col>
          </v-row>
        </div>
      </div>
    </v-card>
  </div>
</template>
<script>
import Axios from "axios";
import { Circle4 } from "vue-loading-spinner";
export default {
  components: {
    Circle4,
  },
  data() {
    return {
      search: "",
      fields: [
        { key: "index", label: "#" },
        { key: "letterNo", label: "شماره نامه" },
        { key: "title", label: "موضوع نامه" },
        { key: "date", label: "تاریخ و ساعت ارسال" },
        { key: "type", label: "نوع نامه" },
        { key: "operation", label: "عملیات" },
      ],
      letters: [],
      currentPage: 1,
      perPage: 10,
      isBusy: false,
      letterType: "all",
      typeOptions: [
        { value: "all", text: "همه" },
        { value: "inbox", text: "ورودی" },
        { value: "outbox", text: "خروجی" },
        { value: "internal", text: "سازمانی" },
      ],
      isLoaded: false,
      perPageOptions: [10, 15, 20],
    };
  },
  methods: {
    goToFirst() {
      this.currentPage = 1;
    },
    goToLast() {
      this.currentPage = Math.ceil(this.letters.length / this.perPage);
    },

    getLetters() {
      Axios.post(
        this.baseUrl + "/clinic/secretariat/letters/archives",
        {},
        {
          headers: {
            Authorization: "Bearer: " + localStorage.getItem("token"),
          },
        }
      )
        .then((res) => {
          if (res.status == 200) {
            this.letters = res.data;
            this.isLoaded = true;
          } else {
            const title = (
              <div>
                <h5>خطا </h5>
                <span>مشکلی پیش آمده. مجددا امتحان کنید.</span>
              </div>
            );
            this.toast(title, "error");
          }
        })
        .catch((err) => {
          this.toast("خطا: " + err.response.data, "error");
        });
    },
    removeArchive(id) {
      this.isLoaded = false;
      Axios.post(
        this.baseUrl + "/clinic/secretariat/letter/removeArchive",
        {
          letterId: id,
        },
        {
          headers: {
            Authorization: "Bearer: " + localStorage.getItem("token"),
          },
        }
      )
        .then((res) => {
          if (res.status == 200) {
            this.toast(res.data, "success");
            this.isLoaded = true;
            this.getLetters();
          } else {
            const title = (
              <div>
                <h5>خطا </h5>
                <span>مشکلی پیش آمده. مجددا امتحان کنید.</span>
              </div>
            );
            this.toast(title, "error");
          }
        })
        .catch((err) => {
          this.isLoaded = true;
          this.toast("خطا: " + err.response.data, "error");
        });
    },
  },
  computed: {
    filteredLetters() {
      let filtered = [];
      filtered = this.letters;
      if (this.letterType != "all") {
        return filtered.filter(
          (x) => x.typeEn == this.letterType && x.letterNo.includes(this.search)
        );
      } else {
        filtered = this.letters.filter((x) => x.letterNo.includes(this.search));
        return filtered;
      }
    },
  },
  mounted() {
    this.getLetters();
  },
};
</script>
<style lang="scss">
* {
  font-family: iransans;
}
@font-face {
  font-family: iransans;
  src: url("../../assets/fonts/IRANSans(FaNum).ttf");
}
.darfts-list {
  .letter-card {
    border-radius: 17px;
    padding: 30px 20px 20px 25px;
  }
}
</style>
